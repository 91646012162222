.ShowContent-Row {
  padding: 40px 60px;

  p {
    font-size: 16px;
  }

  @media (max-width:500px) {
    padding: 40px;

  }

  .ShowContent-Row-left {
    padding-left: 80px;
    margin-bottom: 20px;

    @media (max-width:500px) {
      padding-left: 0px;
    }
  }

  .ShowContent-Row-right {
    padding-left: 80px;

    @media (max-width:500px) {
      padding-left: 0px;
    }

  }

}

.ShowContent-Row:last-child {
  margin-bottom: 20px;
}


.WatingRow {
  padding: 60px 0px;
  background-color: #9254de;
  text-align: center;

  @media (max-width:500px) {
    padding: 30px 0px;
  }

  h1 {
    color: white;
    font-size: 32px;
    letter-spacing: 2px;
    margin-bottom: 30px;

    @media (max-width:500px) {
      margin-bottom: 10px;
    }
  }


  .WatingRow-button {
    border: none;
    color: white !important;
    width: 220px !important;
    height: 46px;
    font-size: 18px;
    margin: 14px 50px;
  }

  .WatingRow-button-left {
    .WatingRow-button();
    background-color: #1890ff !important;
  }

  .WatingRow-button-right {
    .WatingRow-button();
    background-color: #ff4d4f !important;
  }

  .WatingRow-button-left:hover {
    color: white !important;
    opacity: 0.8 !important;
  }

  .WatingRow-button-right:hover {
    color: white !important;
    opacity: 0.8 !important;
  }


  button[ant-click-animating-without-extra-node]::after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }

}


.TopImage {
  text-align: center;
  padding: 150px 100px;

  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.7;

  .TopImage-button {
    width: 300px;
    height: 60px;
    letter-spacing: 4px;
    font-size: 20px;
    color: white !important;
    border: none;
  }

  // .TopImage-button:hover {
  //   background-color: #531dab !important;
  // }

  button[ant-click-animating-without-extra-node]::after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
}


.DepartmentRow {
  padding: 50px;

  @media (max-width:500px) {
    padding: 30px;
  }

  .DepartmentRow-teamTitle {
    text-align: center;

    h1 {
      font-size: 30px;
      font-weight: bold;
      color: #262626;

      @media (max-width:500px) {
        margin-bottom: 30px;
      }
    }

  }

  .DepartmentRow-content {
    margin-bottom: 40px;

    @media (max-width:500px) {
      margin-bottom: 20px;
    }

    h2 {
      color: #262626;
      font-size: 24px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: #595959;
    }
  }
}

.DepartmentRow:last-child {
  padding-bottom: 50px;
}


.JoinUsButtonRow {
  padding: 100px;
  text-align: center;

  .JoinUsButtonRow-Button {
    color: white !important;
    width: 240px;
    height: 50px !important;
    background-color: #597ef7 !important;
    font-size: 20px;
    letter-spacing: 2px;

    button[ant-click-animating-without-extra-node]::after {
      border: 0 none;
      opacity: 0;
      animation: none 0 ease 0 1 normal;
    }
  }

  .JoinUsButtonRow-Button:hover {
    background-color: #9254de !important;
  }
}

@height_normal: 80px;
@height_second: 70px;
@height_mini: 60px;

.ApplyForm {
  margin-top: @height_normal;

  @media (max-width : 1147px) {
    margin-top: @height_second;
  }

  @media (max-width : 937px) {
    margin-top: @height_mini;
  }


}