.Carousel {

  width: 100%;

  .backgroungImg {
    height: auto;
    width: 100%;
    text-align: center;
    line-height: 300px;
    opacity: 0.6;
    color: white;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 3px;
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;
    background-size: 100% 100%;
    // background-image: url('./images/U8.jpg');
  }

  .imgShow {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    height: auto;
    opacity: 0.8;
  }
}

@TitleFontSize-normal: 34px;
@TitleFontSize-600: 28px;
@TitleFontSize-450: 18px;

.vedioShow {
  padding: 50px 100px;

  @media (max-width:450px) {
    padding: 20px 40px;
  }

  .vedioShow-title {
    font-size: @TitleFontSize-normal;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 40px;

    @media(max-width:600px) {
      font-size: @TitleFontSize-600;
    }

    @media (max-width:450px) {
      font-size: @TitleFontSize-450;
      margin-bottom: 20px;
    }


  }

  h3 {
    text-align: center;
    font-size: 18px;

    @media(max-width:600px) {
      font-size: 16px;
    }

    @media (max-width:450px) {
      font-size: 12px;
      margin-bottom: 14px;
    }
  }
}



.carPart {
  padding: 50px 200px;

  @media (max-width:782px) {
    padding: 50px 50px;
  }

  @media (max-width:450px) {
    padding: 20px 40px;
  }

  .carPart-title {
    font-size: @TitleFontSize-normal;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;

    @media(max-width:600px) {
      font-size: @TitleFontSize-600;
    }

    @media (max-width:450px) {
      font-size: @TitleFontSize-450
    }
  }

  .background {
    display: inline-block;
    width: 100%;
    height: auto;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      color: white;
      text-align: center;
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; //忘了之前曦哥教的简单居中的方法了

      h1 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 10px;
        color: white;

        @media(max-width:600px) {
          font-size: 20px;
          letter-spacing: 4px;
        }

        @media (max-width:450px) {
          font-size: 12px;
          letter-spacing: 2px;
        }
      }

    }
  }

  .U8 {
    .background();
  }

  .E23 {
    .background();
  }

  .U8:hover {
    opacity: 0.8;
    color: #f0f0f0;
  }

  .E23:hover {
    opacity: 0.8;
    color: #f0f0f0;
  }
}


.joinPart {
  padding: 100px;
  text-align: center;
  background-color: #9254de;
  color: white;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 6px;

  @media(max-width:600px) {
    padding: 80px;
    font-size: 24px;
    letter-spacing: 2px;
  }

  @media (max-width:450px) {
    padding: 40px;
    font-size: 18px;
    letter-spacing: 2px;
  }

}

.sponsorPart {
  padding: 100px;
  background-color: #664BCC;
  color: white;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 6px;

  .sponsorPart-button {
    text-align: right;
    margin-right: 30px;
  }

  @media (max-width:768px) {
    .sponsorPart-button {
      margin-right: 0px;
      margin-bottom: 10px;
      text-align: center;
    }

    text-align: center;
  }

  @media(max-width:600px) {
    padding: 60px;
    font-size: 24px;
    letter-spacing: 2px;
  }

  @media (max-width:450px) {
    padding: 40px;
    font-size: 18px;
    letter-spacing: 2px;
  }

}

.contentPart {
  padding: 50px 20px;
  background-color: #f5f5f5;

  @media (max-width:450px) {
    padding: 20px 20px;
  }

  .contentPart-title {
    margin-bottom: 30px;
    font-size: @TitleFontSize-normal;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;

    @media(max-width:600px) {
      margin-bottom: 20px;
      font-size: @TitleFontSize-600;
    }

    @media (max-width:450px) {
      margin-bottom: 20px;
      font-size: @TitleFontSize-450
    }
  }

  @media (max-width:627px) {
    padding: 20px;
  }
}