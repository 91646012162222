.showContent {
  padding: 80px 0px;
  margin-bottom: 40px;

  @media (max-width:450px) {
    padding: 20px 0px;
    margin-bottom: 20px;
  }

  .showContent-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width:450px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .showContent-content {
    text-align: left;
    font-size: 18px;
    margin-bottom: 30px;

    @media (max-width:450px) {
      font-size: 14px;
    }

    line-height: 1.5;
    color: #595959;
  }


  .showContent-button {
    letter-spacing: 2px;
    background-color: #722ed1;
    color: white;
    font-weight: bold;
    margin-top: 60px;
    width: 220px;

    @media (max-width:450px) {
      width: 160px;
      margin-top: 20px;
      font-size: 4px !important;
    }
  }

  .showContent-button:hover {
    background-color: #597ef7;
    color: white;
  }
}


.sponsorLogo {
  margin-bottom: 80px;

  @media (max-width:450px) {
    margin-bottom: 40px;
  }

  .sponsorLogo-title {
    letter-spacing: 4px;
    font-size: 30px;
    font-weight: bold;
    color: #595959;
    margin: 80px 0px;

    @media (max-width:450px) {
      font-size: 24px;
      margin: 20px 0px;
    }
  }

  .sponsorLogo-empty {
    font-size: 16px;

    @media (max-width:450px) {
      font-size: 12px;
    }

    color: #8c8c8c;
    letter-spacing: 1px;
  }

}

.ButtonRow {
  text-align: center;
  margin: 100px 0px;


  .ButtonRow-Button {
    margin: 15px 0;
    width: 80%;
    height: 50px;
    letter-spacing: 4px;
    font-weight: bold;
    color: white !important;
    border: none;
  }

  .ButtonRow-Button:hover {
    opacity: 0.8;
  }

  button[ant-click-animating-without-extra-node]::after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }

}


.tobeContent {
  text-align: center;
  margin: 100px 0px;

  .tobeContent-h1 {
    font-weight: bold;
    margin-bottom: 70px;
  }

  .tobeContent-get {
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 60px;
  }

  .tobeContent-title {
    font-size: 20px;
    font-weight: bold;
    color: #434343;
    margin-bottom: 20px;
  }

  .tobeContent-content {
    margin-bottom: 80px;
    font-size: 16px;
    color: #595959;
    letter-spacing: 1px;
    line-height: 2.8;
    white-space: pre-wrap; // \n换行
  }

  .tobeContent-contact {
    h1 {
      margin: 40px 0;
    }

    div {
      width: 300px;
      margin: auto;

      p {
        font-size: 16px;
        text-align: left;
      }
    }
  }
}