.Car-Introduaction {
  background-color: #fafafa;
  padding: 60px;

  @media (max-width:414px) {
    height: 150px;
    font-size: 22px;
  }

  div {
    white-space: pre-wrap; // \n换行
    line-height: 1.5;
    font-size: 22px;
    letter-spacing: 2px;
    color: #595959;
  }
}

.CarDataRow {
  padding: 50px 0px;

  .CarDataRow-img {
    width: 80%;
    height: auto;
    margin: auto auto;
  }

  p {
    font-size: 14px;
    color: #595959;
  }

}

.Car-IntroduactionRow {
  background-color: #fafafa;
  padding: 60px 0px;
  white-space: pre-wrap; // \n换行

  @media (max-width:700px) {
    padding: 30px;
  }

  .Car-IntroduactionRow-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }

  .Car-IntroduactionRow-content {
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
  }

}