@height_normal: 80px;
@height_second: 70px;
@height_mini: 60px;

.header {
  z-index: 99999;
  position: fixed;
  width: 100%;
  top: 0;
  background: #262626;

  // background-color: #001529;


  height: @height_normal;

  @media (max-width : 1147px) {
    height: @height_second;
  }

  @media (max-width : 937px) {
    height: @height_mini;
  }


  // 说实话，写完之后我也不知道怎么动画就成功跑起来了。。。。
  .header-drawerSelf {
    display: flex;
    flex-direction: column;
    height: 32px;
    width: 30px;

    @drawer-width: 30px;
    @drawer-heigth: 1px;

    .transition {
      width: @drawer-width;
      height: @drawer-heigth;
      background-color: white;
      transition: 0.65s;
      -moz-transition: 0.65 cubic-bezier(.04, .04, .12, .96);
      /* Firefox 4 */
      -webkit-transition: 0.65 cubic-bezier(.04, .04, .12, .96);
      /* Safari and Chrome */
      -o-transition: 0.65 cubic-bezier(.04, .04, .12, .96);
      /* Opera */
    }

    .header-drawerSelf-top {
      .transition();
      margin-top: 10px;

    }

    .header-drawerSelf-bottom {
      margin-top: 10px;
      .transition();
    }

    .header-drawerSelf-top-checked {
      margin-top: 10px;
      .transition();
    }

    .header-drawerSelf-bottom-checked {
      margin-top: 10px;
      .transition();
    }

  }

  .header-drawerSelf:hover,
  .header-drawerSelf:active {

    .header-drawerSelf-top-checked {
      margin-top: 15px;
      transform: rotate(45deg);
    }

    .header-drawerSelf-bottom-checked {
      margin-top: 0px;
      transform: rotate(-45deg);
    }


  }


}


.header-menu {

  font-size: 18px;

  background: #262626 !important;

  display: flex;
  flex-direction: row;
  justify-content: center;



  .header-menu-item {
    text-align: center !important;
    width: 140px;

    @media (max-width : 1532px) {
      width: 120px;
    }

    @media (max-width : 1374px) {
      width: 100px;
    }

    @media (max-width : 1147px) {
      width: 80px;
      font-size: 16px;
    }

    @media (max-width : 937px) {
      width: 60px;
      font-size: 12px;
    }
  }

  .header-menu-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;

    img {
      height: 50px;

      @media (max-width : 937px) {
        height: 40px;
      }
    }

    @media (max-width : 1532px) {
      width: 230px;
    }

    @media (max-width : 1374px) {
      width: 210px;
    }

    @media (max-width : 1147px) {
      width: 170px;
    }

    @media (max-width : 937px) {
      width: 140px;
    }
  }

  .header-menu-subMenu {
    background-color: #262626 !important;
  }

  // // 设置菜单样式
  // .ant-menu,
  // .ant-menu-sub,
  // .ant-menu-inline {
  //   color: white;
  //   background-color: #262626 !important;
  // }

  // // // 设置子菜单展开样式
  // .ant-menu-submenu>.ant-menu {
  //   background-color: white !important;
  // }

  // .ant-menu-submenu-title {
  //   color: white !important;
  // }



  // 选中菜单状态
  .ant-menu-item-selected {
    background-color: #262626 !important;
    color: white !important;
  }

  // 设置未选中菜单项鼠标滑过样式
  .ant-menu-item-active {
    background-color: #262626 !important;
    color: white !important;
  }

}


.header-drawerDwon {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: @height_normal;

  @media (max-width : 1147px) {
    top: @height_second;
  }

  @media (max-width : 937px) {
    top: @height_mini;
  }
}