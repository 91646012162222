@backgrounColor :#262626;
// @backgrounColor: #001529;

.Footer {
  background: @backgrounColor;
  padding: 30px 0px;
  text-align: center;
  color: white;

  .Footer-center {
    text-align: center;
    color: #bfbfbf;
    font-size: 18px;
    letter-spacing: 6px;

    @media (min-width:1900px) {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .Footer-right {
    color: #8c8c8c;
    font-size: 12px;
    text-align: left;

    @media (min-width:1900px) {
      font-size: 16px;
    }

    @media (max-width:450px) {
      font-size: 10px;
      margin-top: 10px;
    }
  }

  @media (max-width:768px) {
    padding: 20px 0px;

    .Footer-center {
      text-align: center;
      color: #bfbfbf;
      font-size: 14px;
      letter-spacing: 2px;
      margin-bottom: 6px;
    }
  }
}


.Footer-beian {
  padding-bottom: 18px;

  @media (max-width:450px) {
    padding-bottom: 10px;
    font-size: 10px;
  }

  background-color: @backgrounColor;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    color: #bfbfbf !important;
  }
}