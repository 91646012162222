.TeamPhotoShow {
  padding: 60px 0px;

  @media (max-width:450px) {
    padding: 20px 0px;
  }

  .TeamPhotoShow-teamName {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 50px;
  }

  .TeamPhotoShow-photo {
    position: relative;
    display: inline-block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .TeamPhotoShow-introduction {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 998;
      background-color: #000;
      opacity: 0.6;
      color: white;
      text-align: center;
      font-size: 22px;
      line-height: 1.5;
      white-space: pre-wrap; // \n换行
      display: none;
    }
  }

  .TeamPhotoShow-photo:hover {
    .TeamPhotoShow-introduction {
      display: block;
    }
  }

  .TeamPhotoShow-name {
    font-size: 22px;
    text-align: center;
  }
}