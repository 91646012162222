.MediaRow {
  padding: 80px;

  .MediaRow-background {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-position: 0% 0%;
    border: 1px solid #f0f0f0;
    opacity: 0.7;

    div {
      font-size: 36px;
      font-weight: bold;
      line-height: 400px;
      color: #434343;
    }

    text-align: center;
  }

  .MediaRow-background:hover {
    opacity: 0.6;
    color: #595959;
  }


}