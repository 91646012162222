@height_normal: 80px;
@height_second: 70px;
@height_mini: 60px;

.TopImageShow {
  opacity: 0.8;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-top: @height_normal;

  @media (max-width : 1147px) {
    margin-top: @height_second;
  }

  @media (max-width : 937px) {
    margin-top: @height_mini;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    color: white;
    text-align: center;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; //忘了之前曦哥教的简单居中的方法了

    h1 {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 10px;
      color: white;

      @media (max-width:450px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 24px;
      // font-weight: bold;
      letter-spacing: 6px;
      color: #f0f0f0;

      @media (max-width:500px) {
        font-size: 14px;
      }
    }
  }

}