.error {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    line-height: 1;
    text-align: center;
  }

  img {
    width: 40vw;

    @media(max-width: 1000px) {
      width: 70vw;
    }
  }
}