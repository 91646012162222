.SolganPart {
  padding: 100px;
  background-color: #fafafa;
  color: black;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 6px;

  span {
    margin: auto;
  }

  @media (max-width:790px) {
    padding: 80px;
    font-size: 24px;
  }

  @media (max-width:670px) {
    padding: 60px;
    font-size: 18px;
  }

  @media (max-width:494px) {
    padding: 40px;
    font-size: 16px;
  }

}