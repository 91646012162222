.introduction {
  padding: 50px;

  h1 {
    font-size: 24px;
    line-height: 1.5;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
  }

  .img {
    max-width: 600px;
  }
}

.TeamStructure {
  border-top: 1px solid #eeeeee;
  padding: 50px;
}